*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Raleway', 'PT Sans', sans-serif;
}

.OrganeGestion_Intro_title{
	margin-top: 0 !important;
}

.OrganeGestion_Intro_title h1{
	/* background-color: #286f4e; */
	background-image: linear-gradient(rgba(0, 81, 186, 0.5), rgba(0, 81, 186, 0.5)), url('../assets/IMG-20210701-WA0030.jpg') !important;
	background-repeat: no-repeat;
	background-size: cover;
	font-weight: bold;
	color: #ffffff;
	text-align: center;
	font-size: 3.5rem;
	padding: 300px 150px 300px 150px;
}

.OrganeGestion_Intro_img{
	display: grid;
	justify-content: center;
	align-items: center;
}

.OrganeGestion_Intro_img img{
	width: 900px;
	height: auto;
	margin-top: -150px;
}

.OrganeGestion_exp .OrganeGestion_exp_Intro{
	display: grid;
	justify-content: center;
	align-items: center;
}

.OrganeGestion_exp .OrganeGestion_exp_Intro p{
	text-align: center;
	color: #286f4e;
	font-weight: bold;
	width: 600px;
	margin: 50px 0 0 0;;
}

.OrganeGestion_exp_Buttons{
	display: flex;
	justify-content: center;
	align-items: center;
}

.OrganeGestion_exp_Buttons{
	margin-top: 50px;
}

.OrganeGestion_exp_Buttons .button_1{
	background-color: #286f4e;
	color: white;
	text-decoration: none;
	font-weight: bold;
	padding: 10px;
	cursor: pointer;
}

.OrganeGestion_exp_Buttons .button_2{
	background-color: #286f4e;
	color: white;
	text-decoration: none;
	font-weight: bold;
	padding: 10px;
	margin-left: 10px;
	cursor: pointer;
}

.OrganeGestion_Conseil{
	margin-top: 70px;
	margin-bottom: 70px;
}

.OrganeGestion_Conseil_title h2{
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	color: #286f4e;
	font-size: 2.2rem;
	margin-bottom: 30px;
	position: relative;
}

.OrganeGestion_Conseil_title h2::after {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	width: 80px;
	height: 3px;
	background-color: #286f4e;
}

.OrganeGestion_Coord{
	margin-top: 70px;
	margin-bottom: 70px;
}

.OrganeGestion_Exp{
	position: relative;
	/* display: flex; */
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	margin-top:0;
	border-radius: 0;
	background-color: transparent;
	box-shadow: none;
	transition: none;
	overflow: visible;
	border: none;
	height: auto;
}

.OrganeGestion_Exp:hover {
	transform: none;
}

.OrganeGestion_Img {
	overflow: hidden;
	margin-bottom: 10px;
	border: none;
	box-shadow: none;
	position: relative;
	flex-shrink: 0;
	top: 0;
	left: 0;
	transform: none;
	z-index: 1;
}

.OrganeGestion_Img img{
	width: 100%;
	height: 400px;
	object-fit: cover;
	filter: grayscale(100%);
	transition: filter 0.3s ease;
}

.OrganeGestion_Exp:hover .OrganeGestion_Img img {
	filter: grayscale(0);
}

.OrganeGestion_Function {
	width: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	background-color: transparent;
	box-shadow: none;
	z-index: 1;
	transition: none;
}

.OrganeGestion_Exp:hover .OrganeGestion_Function {
	background-color: transparent;
	box-shadow: none;
}

.OrganeGestion_Exp .OrganeGestion_Function h3 {
	color: #333;
	font-weight: 500;
	font-size: 1.1rem;
	margin-bottom: 3px;
	position: relative;
	padding-bottom: 0;
	text-align: center;
}

.OrganeGestion_Exp .OrganeGestion_Function h3:after {
	content: none;
}

.OrganeGestion_Exp:hover .OrganeGestion_Function h3:after {
	width: 0;
}

.OrganeGestion_Exp .OrganeGestion_Function p{
	color: #666;
	font-weight: 400;
	font-size: 0.85rem;
	position: relative;
	text-transform: none;
	letter-spacing: 0;
}

.OrganeGestion_Conseil_Identity{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
	gap: 30px;
	padding: 20px 80px;
	max-width: 1600px;
	margin: 0 auto;
}

@media screen and (max-width: 992px) {
	.OrganeGestion_Intro_title h1{
		background-color: #286f4e;
		color: #ffffff;
		text-align: center;
		font-size: 2rem;
		padding: 150px 10px 300px 10px;
	}

	
	.OrganeGestion_Intro_img{
		display: grid;
		justify-content: center;
		align-items: center;
	}

	
	.OrganeGestion_Intro_img{
		padding: 10px;
		display: grid;
		justify-content: center;
		align-items: center;
	}

	.OrganeGestion_Intro_img img{
		width: 100%;
		height: auto;
		margin: -150px 20px 20px 0;
	}

	.OrganeGestion_exp .OrganeGestion_exp_Intro p{
	text-align: center;
	color: #286f4e;
	font-weight: bold;
	width: 100%;
	font-size: 0.8rem;
	margin: 50px 0 0 0;
	padding: 0 50px 0 50px;
	}

	
	.OrganeGestion_exp_Buttons{
		display: grid;
		justify-content: center;
		align-items: center;
	}

		
	.OrganeGestion_exp_Buttons .button_1{
		background-color: #286f4e;
		color: white;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
		padding: 10px;
	}

	.OrganeGestion_exp_Buttons .button_2{
		background-color: #286f4e;
		color: white;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
		padding: 10px;
		margin: 10px 0 0  0;
	}

	
	.OrganeGestion_Conseil_Identity{
		grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
		gap: 10px;
		padding: 20px 20px;
	}
	
	.OrganeGestion_Exp {
		margin-top: 0;
	}
	
	/* .OrganeGestion_Img {
		width: 150px;
		height: 200px;
	} */
	
	.OrganeGestion_Function {
		padding: 0;
	}
	
	.OrganeGestion_Exp .OrganeGestion_Function h3 {
		font-size: 1rem;
	}
	
	.OrganeGestion_Exp .OrganeGestion_Function p {
		font-size: 0.8rem;
	}
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
	.OrganeGestion_Conseil_Identity {
		padding: 20px 50px 0 50px;
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	}
}