* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'PT Sans', sans-serif;
}

.footer_contents {
	background-color: #f0f0f0;
	/* padding: 80px 250px 0; */
}

.footer_details {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-template-rows: repeat(auto-fit, minmax(auto, 1fr));
	/* margin-top: 80px; */
	gap: 100px;
	padding: 100px 250px 100px 250px ;
}

.acndc_logo {
	width: 100%;
}

/* .acndc_logo_img {
	display: flex;
	justify-content: center;
	align-items: center;
} */

.acndc_logo img {
	width: 30%;
	height: auto;
}

.acndc_logo_text {
	padding: 10px 10px 10px 10px;
	/* text-align: center; */
}

.acndc_logo_text h2 {
	color: #000000;
	font-size: 1rem;
	font-weight: bold;
}

.address {
	display: block;
	justify-content: center;
	align-items: center;
	
	/* background-color: darkblue; */
	/* border-left: 1px solid rgb(156, 156, 156);
	border-right: 1px solid rgb(156, 156, 156);
	padding: 0 100px; */
}

.address_text_tit{
	font-size: 1.3rem;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 20px;
}

.address .address_text h3 {
	/* font-weight: bold; */
	color: #000000;
	font-size: 1rem;
}

.address .address_text i {
	margin-right: 10px;
}

.address_links {
	margin-top: 30px;
}

.address_links .address_link_text h5 {
	color: #000000;
}

.address_link_icons {
	padding: 10px;
	display: flex;
	gap: 20px;
	/* justify-content: space-between 	; */
}

.address_link_icons .address_link_icon {
	color: #000000;
	font-size: 2rem;
}

.address_link_icons .address_link_icon i:hover {
	color: #000000;
}

.footer_links {
	/* height: 10%; */
	/* margin-top: 50px; */
	/* display: grid; */
	justify-content: center;
	align-items: center;
}

.footer_links .footer_link {
	text-decoration: none;
	font-weight: bold;
	color: #000000;
	font-family: 'PT Sans', sans-serif;
	font-size: 1rem;
}

.footer_links .footer_link:hover {
	color: #01bd55;
}

.alright {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #ffffff;
	/* border-radius: 20px; */
	margin: 40px 0;
	padding: 0 50px;
}

.alright h3 {
	/* margin-top: 80px; */
	color: #000000;
	text-align: center;
	font-size: 0.8rem;
	margin-bottom: 0 !important;
}

.asdpv_logo_contact p{
	color: #000000;
}

.asdpv_logo_contact p i{
	padding-right: 10px;
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
	.footer_contents {
		background-color: #f0f0f0;
		/* padding: 40px 200px 0; */
	}

	.footer_details{
		gap: 50px;
	}
}


@media screen and (min-width: 960px) and (max-width: 1400px) {
	.footer_contents {
		background-color: #f0f0f0;
		/* padding: 40px 50px 0; */
	}

	.footer_details{
		gap: 50px;
		padding: 150px 50px 100px 50px ;
	}

	.alright {
		display: grid;
		padding: 30px 30px;
		justify-content: center;
		align-items: center;
	}

	.address_link_icons {
		justify-content: center;
	}
}


@media screen and (max-width: 960px) {

	.footer_contents {
		/* padding: 10px 10px 10px 10px; */
	}

	.footer_details {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		grid-template-rows: repeat(auto-fit, minmax(auto, 1fr));
		/* margin-top: 80px; */
		gap: 20px;
		padding: 150px 50px 100px 50px ;
	}

	.acndc_logo_text {
		padding: 30px 10px 30px 10px;

	}

	.address {
		border-left: none;
		border-right: none;
		padding: 0 0 40px 0;
	}

	.address .address_text h3 {
		padding: 0;
	}

	.address_links {
		margin-top: 30px;
		padding: 0 10px;
		text-align: center;
		display: grid;
		justify-content: center;
	}

	.address_links .address_link_icons {
		display: flex;
		justify-content: space-between;
	}

	.footer_links .footer_link {
		text-align: center;
	}

	
	.alright {
		display: grid;
		padding: 30px 30px;
		justify-content: center;
		align-items: center;
	}

	.address_link_icons {
		justify-content: center;
	}
}