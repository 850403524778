* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Raleway', 'PT Sans', sans-serif;
}

.Introduction_container {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.intro_contents {
	display: flex;
	transition: transform 0.5s ease-in-out;
	height: 100%;
}

.intro_details_text {
	min-width: 100%;
	height: 800px;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;

}

#slide_section1 {
	position: relative;
	background-image: url('../assets/IMG-20210701-WA0032.jpg');
}

#slide_section2 {
	position: relative;
	background-image: linear-gradient(rgba(186, 171, 0, 0.5),
			rgba(186, 171, 0, 0.5)), url('../assets/IMG-20210701-WA00281.jpg');
}

#slide_section3 {
	position: relative;
	background-image: linear-gradient(rgba(186, 59, 0, 0.5),
			rgba(186, 59, 0, 0.5)), url('../assets/IMG-20210701-WA0032.jpg');
}

.slider_nav {
	position: absolute;
	top: 50%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	transform: translateY(-50%);
}

.nav_button {
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 18px;
	border-radius: 5px;
	transition: background-color 0.3s ease;
}

.nav_button:hover {
	background-color: rgba(0, 0, 0, 0.8);
}


.intro_details_contents {
	background-color: #00632C;
}

/*  .intro_details_text { */
/* width: 100%; */
/* height: 750px; */
/* background-image: url('../assets/IMG-20210701-WA0032.jpg'); */
/* background-repeat: no-repeat;
	background-size: cover;
	min-width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center; */

/* } */

.intro_details_text .intro_text {
	/* padding: 200px 200px;
	text-align: center; */
	margin-left: auto;
	margin-right: auto;
	max-width: 60%;
	text-align: center;
}

.intro_text h1 {
	color: white;
	line-height: 1.2;
	font-size: 5rem;
	width: 100%;
	height: auto;
	font-weight: bold;
	margin: 20px 0;
	text-transform: uppercase;
	font-weight: 900;
}

.intro_text p {
	color: white;
	font-size: 1.2rem;
	width: 100%;
}

.intro_text .Intro_button {
	background-color: white;
	border: none;
	padding: 20px 50px;
	margin-top: 10px;
}

.intro_text .Intro_button .Intro_but {
	font-weight: bold;
	color: #00632C;
}

.intro_text .Intro_button .Intro_but:hover {
	color: #113f26;
	cursor: pointer;
}

.intro_images {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -170px;
}

.intro_images .intro_img_1 {
	width: auto;
	height: 300px;
	border-radius: 20px;
}

.intro_images .intro_img_2 {
	width: auto;
	height: 350px;
	border-radius: 20px;
	margin-left: 20px;
	margin-top: -200px;
}

.Slogan_container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Slogan_content {
	padding: 100px;
	width: 70%;
	height: auto;
}

.Slogan_content h1 {
	color: #008f40;
	text-align: center;
	font-weight: bold;
}

.Vision_content {
	/* padding: 70px 80px; */
	margin: 0 100px;
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) */
}

#Vision {
	background-color: rgb(233, 233, 233);
	padding: 80px 80px;
}

#Mission {
	background-color: rgb(245, 245, 245);
	padding: 80px 80px;
}

#Objectif {
	background-color: rgb(233, 233, 233);
	padding: 80px 80px;
}

.Vision_content .Vision_Boxes {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: auto;
	/* gap: 80px; */
}

.Vision_content .Vision_Boxes .Vision_Box img {
	width: 50px;
	height: auto;
}

.Vision_content .Vision_Boxes .Vision_Box .Vision_Img {
	display: grid;
	align-items: center;
}

.Vision_content .Vision_Boxes .Vision_Box .Vision_Title p {
	color: #000000;
	margin: 30px 0 30px 0;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 1rem;
}

/* .Vision_content .Vision_Boxes .Vision_Box .Vision_detail{
	 margin: 30px 80px 30px 80px;
} */

.Vision_content .Vision_Boxes .Vision_Box .Vision_detail p {
	color: #000000;
	/* font-weight: bold; */
	line-height: 1.2;
	font-size: 1rem;
}

.About_contents .About_details {
	display: flex;
	justify-content: space-around;
	margin: 70px 100px;
	background-color: #45775b;
}

.About_contents .About_details .About_Img,
.About_contents .About_details .About_Text {
	width: 50%;
}

.About_contents .About_details .About_Img {
	display: grid;
	justify-content: center;
	align-items: center;
}

.About_contents .About_details .About_Img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.About_contents .About_details .About_Text .About_txt {
	/* display: grid; */
	justify-content: center;
	align-items: center;
	padding: 100px 100px;
}

.About_contents .About_details .About_Text h2 {
	color: #ffffff;
	font-size: 2rem;
	font-weight: 900;
	text-transform: uppercase;
}

.About_contents .About_details .About_Text p {
	text-align: left;
	margin: 30px 0 30px 0;
	color: #ffffff;
	/* font-weight: bold; */
}

.AboutButton {
	color: #00632C;
	background-color: #ffffff;
	padding: 10px 10px 10px 10px;
	font-weight: bold;
	text-decoration: none;
	/* border-radius: 10px; */
}

.AboutButton:hover {
	background-color: #eeeeee;
	color: #113f26;
}

.Actualite_details {
	margin: 80px 80px 80px 80px;
	/* width: 100%; */
}

.Actualite_details .Actualite_header {
	margin-left: 40px;
}

.Actualite_details .Actualite_header h2 {
	color: #00632C;
	font-size: 2rem;
	font-weight: bold;
}

.Actualite_details .Actualite_boxes {
	margin-top: 40px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	gap: 15px;
	padding: 0;
}

.Actualite_details .Actualite_boxes .Actualite_box {
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
	margin: 30px 0 0 0;
	position: relative;
	/* background-image: linear-gradient(rgba(186, 171, 0, 0.5),
			rgba(186, 171, 0, 0.5)), url('../assets/IMG-20210701-WA00281.jpg'); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 600px;
}


.Actualite_details .Actualite_boxes .Actualite_box_Img img {
	width: 100%;
	object-fit: fill;
}

.Actualite_box .Actualite_box_text {
	padding: 25px 25px !important;
	margin: 20px 25px !important;
	/* margin: 50px 50px; */
	background-color: white;
	position: absolute;
	bottom: 0;
}

.Actualite_box .Actualite_box_text .Actualite_box_text_head p {
	/* background-color: #dddddd; */
	width: 90px;
	padding: 5px;
	color: white;
}

.Actualite_box .Actualite_box_text .Actualite_box_text_tit p {
	margin-top: 8px;
	background-color: #00632C;
	font-weight: bold;
	color: white;
	padding: 10px;
}

.Actualite_box .Actualite_box_text .Actualite_box_text_cont p {
	margin: 20px 0 20px 0;
	text-align: left !important;
}

.Actualite_box {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.SavoirPlus {
	/* background-color: #dddddd; */
	/* width: 100%; */
	padding: 5px;
	border: none;
	text-decoration: none;
	color: black;
}

.SavoirPlus:hover {
	/* background-color: #b4b4b4; */
	cursor: pointer;
}

.SavoirPlus .fas {
	margin-left: 10px;
}

.Actualite_Button {
	display: grid;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
}

.Actualite_Button .Act_Button {
	background-color: #3cb371;
	padding: 20px;
	color: white;
	font-weight: bold;
	text-decoration: none;
	font-size: 1.2rem;
}

.Actualite_Button .Act_Button:hover {
	background-color: #113f26;
}

.video_header h2 {
	text-align: center;
	text-transform: uppercase;
	font-size: 3rem;
	font-weight: bold;
	color: #00632C;
}

.video_details {
	padding-top: 5rem;
	background-color: #e9e9e9;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content img {
	border-radius: 5px !important;
}

.react-3d-carousel .slider-container .slider-right div {
	border: none !important;
	background-color: white;
	color: black !important;
}

.react-3d-carousel .slider-container .slider-left div {
	border: none !important;
	background-color: white;
	color: black !important;
}

.video_details .home_video_slider_cards {
	/* display: flex;
	align-items: center;
	justify-content: center; */
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
	gap: 20px;
	padding: 0 150px;
	padding-bottom: 50px;
}

.video_details .home_video_slider_cards video {
	width: 100%;
	height: auto;
	margin-bottom: 50px;
}

@media screen and (min-width: 992px) and (max-width: 992px) {}

@media screen and (max-width: 992px) {
	.intro_details_text .intro_text {
		/* padding: 60px 0 0 0; */
		display: grid;
		justify-content: center;
		align-items: center;
		max-width: 80%;
	}

	.intro_text h1 {
		color: white;
		font-size: 2.5rem;
		text-align: center;
		padding: 0 10px 0 10px;
		width: 100%;
	}

	.intro_text p {
		color: white;
		font-size: 1rem;
		padding: 0 10px 0 10px;
		width: 100%;
		text-align: center;
	}

	.intro_text .Intro_button {
		margin: 20px 50px 20px 50px;
	}

	.intro_images {
		display: grid;
		margin-top: -250px;
		padding: 0 20px 0 20px;
	}

	.intro_images .intro_img_1 {
		border-radius: 20px;
		width: 100%;
		height: auto;
		object-fit: fill;
	}

	.intro_images .intro_img_2 {
		width: 100%;
		height: auto;
		object-fit: fill;
		border-radius: 20px;
		margin-left: 0;
		margin-top: 20px;
	}

	.Slogan_content {
		padding: 30px 20px 30px 20px;
		width: 100%;

	}

	.Slogan_content h1 {
		color: #00632C;
		font-size: 1.2rem;
		text-align: center;
	}


	#Mission {
		width: 100%;
		padding: 80px 40px;
	}

	#Vision {
		padding: 80px 40px;
		width: 100%;
	}

	#Objectif {
		width: 100%;
		padding: 80px 40px;
	}

	.Vision_content {
		/* background-color: rgb(236, 236, 236); */
		padding: 0 50px 150px 50px;
		margin: 0;
	}


	.Vision_content .Vision_Boxes {
		display: block;
		justify-content: space-between;
		width: 100%;
		height: auto;
	}

	.Vision_content .Vision_Boxes .Vision_Box {
		margin-top: 0px;
	}

	.Vision_content .Vision_Boxes .Vision_Box .Vision_detail {
		margin: 0 0 0px 0;
	}

	.About_contents .About_details {
		display: block;
		justify-content: space-around;
		padding: 0;
		margin: 70px 30px;
	}

	.About_contents .About_details .About_Img,
	.About_contents .About_details .About_Text {
		width: 100%;
	}

	.About_contents .About_details .About_Img {
		display: grid;
		justify-content: center;
		align-items: center;
	}

	.About_contents .About_details .About_Img img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.About_contents .About_details .About_Text {
		padding: 30px 10px 10px 10px;
		display: grid;
		justify-content: center;
		align-items: center;
	}


	.About_contents .About_details .About_Text .About_txt {
		justify-content: center;
		align-items: center;
		padding: 50px 50px;
	}

	.Actualite_details {
		margin: 30px 30px 30px 30px;
	}

	.Actualite_details .Actualite_header {
		margin-left: 0px;
	}

	.Actualite_details .Actualite_header h2 {
		color: #00632C;
		font-size: 2.5rem;
	}

	.Actualite_details .Actualite_header p {
		font-size: 0.6rem;
	}

	.Actualite_details .Actualite_boxes {
		display: grid;
	}

	.Actualite_details .Actualite_boxes .Actualite_box {
		margin-left: 0px;
		margin-top: 30px;
	}

	.video_details .home_video_slider_cards {
		display: block;
		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
		gap: 20px;
		padding: 0 30px;
	}


	.video_details .home_video_slider_cards video {
		width: 100%;
		height: auto;
		margin: 50px 0 0 0;
	}


}


@media screen and (max-width: 600px) {

	.intro_images {
		display: grid;
		margin-top: -200px;
		padding: 0 20px 0 20px;
	}

	.intro_images .intro_img_1 {
		border-radius: 20px;
		width: 100%;
		height: auto;
		object-fit: fill;
	}

	.intro_images .intro_img_2 {
		width: 100%;
		height: auto;
		object-fit: fill;
		border-radius: 20px;
		margin-left: 0;
		margin-top: 20px;
	}

	.video_header h2 {
		font-size: 2rem;
	}


}