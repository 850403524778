* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Raleway', 'PT Sans', sans-serif;
}

.rapport_detail_Intro {
	margin-top: 0 !important;
}

.rapport_detail_Intro h1 {
	font-weight: bold;
	background-color: #286f4e;
	color: #ffffff;
	text-align: center;
	font-size: 3.5rem;
	padding: 200px 150px 150px 150px;
}

.rapport_detail_date {
	margin-top: -80px;
	display: grid;
	justify-content: center;
	align-items: center;
}

.rapport_detail_date h2 {
	padding: 20px;
	width: 200px;
	height: auto;
	color: #286f4e;
	background-color: #c2fbe0;
	font-weight: bold;
	text-align: center;
}

.rapport_detail_comp {
	display: grid;
	justify-content: center;
	align-items: center;
}

.rapport_detail_comp_txt {
	margin: 50px 0 50px 0;
	width: 600px;
	height: auto;
}

.download_button {
	display: grid;
	justify-content: center;
	align-items: center;
}
.download_link {
	padding: 10px;
	color: white;
	font-size: 1rem;
	font-weight: bold;
	text-decoration: none;
	background-color: #286f4e;
	text-align: center;
	margin: 50px 0 50px 0;
	border: none;
	cursor: pointer;
}

.download_link:hover {
	background-color: #0d432a;
}

.download_link I {
	margin-left: 10px;
}

.acndc_programme_2022 ul{
	margin-left: 2rem;
}



@media screen and (max-width: 992px) {

	.rapport_detail_Intro h1 {
		font-size: 2.5rem;
		padding: 150px 10px 150px 10px;
	}


	.rapport_detail_comp_txt {
		margin: 50px 0 50px 0;
		padding: 0 10px 0 10px;
		width: 100%;
		text-align: justify;
		height: auto;
	}
}