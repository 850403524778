*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Raleway', 'PT Sans', sans-serif;
}

.Objectif_Intro{
	margin-top: 0 !important;
}

.Objectif_Intro h1{
	background-color: #286f4e;
	color: #ffffff;
	text-align: center;
	font-size: 3.5rem;
	padding: 200px 150px 150px 150px;
	font-weight: bold;
}

.Objectif_textes_box{
	margin: 50px 0 50px 0;
	display: grid;
	justify-content: center;
	align-items: center;
}

.Objectif_1{
	display: flex;
	margin-top: 100px;
	margin-bottom: 50px;
}

.Objectif_1 p{
	margin-left: 10px;
	font-size: 1.1rem;
}

.Objectif_1 i{
	font-size: 2rem;
}

.Objectif_textes{
	width: 600px;
	height: auto;
	color: #3cb371;
	text-align: justify;
}


@media screen and (max-width: 992px) {

	.Objectif_Intro h1{
		font-size: 2.5rem;
		padding: 150px 10px 150px 10px;
	}

	.Objectif_textes_box{
		margin: 50px 20px 50px 20px;
		display: grid;
		justify-content: center;
		align-items: center;
	}
	
	.Objectif_textes{
		width: 100%;
		height: auto;
		color: #3cb371;
		text-align: left;
	}

	
	.Objectif_1 p{
		margin-left: 10px;
		font-size: 1rem;
	}

	
	.Objectif_1 i{
		font-size: 1.5rem;
	}
}