*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Raleway', 'PT Sans', sans-serif;
}

.outilsTravail_Intro{
	margin-top: 0 !important;
}

.outilsTravail_Intro h1{
	font-weight: bold;
	background-color: #286f4e;
	color: #286f4e;
	text-align: center;
	font-size: 3.5rem;
	padding: 100px 150px 100px 150px;
}

.outilsTravail_boxes{
	display: flex;
	justify-content: center;
	margin: 100px 0 100px 0;
}

.outilsTravail_boxes H2{
	color: #286f4e;
	font-weight: bold;
}

.outilsTravail_boxes .outilsTravail_box{
	border: 2px solid rgb(184, 184, 184);
	border-left: 10px solid #286f4e;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 20px;
	display: grid;
	justify-content: center;
	margin-left: 30px;
}

.outilsTravail_boxes .outilsTravail_box i{
	margin: 30px 0 30px 0;
	color: rgb(194, 0, 0);
	font-size: 10rem;
	text-align: center;
}

.view_class{
	display: grid;
	justify-content: center;
}

.view_class .view_button{
	background-color: #286f4e;
	color: white;
	text-decoration: none;
	font-weight: bold;
	padding: 10px;
	border-radius: 10px;
}




@media screen and (max-width: 992px) {

	.outilsTravail_Intro h1{
		font-size: 2.5rem;
		padding: 100px 10px 100px 10px;
	}

	
	.outilsTravail_boxes{
		display: grid;
		margin: 100px 0 100px 0;
	}

	
	.outilsTravail_boxes .outilsTravail_box{
		margin: 0 0 20px 0;
	}
}