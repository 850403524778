*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Raleway', 'PT Sans', sans-serif;
}

.Resultats_Intro{
	margin-top: 0 !important;
}

.Resultats_Intro{
	background-color: #3cb371;
	background-image: linear-gradient(rgba(186, 171, 0, 0.5), rgba(186, 171, 0, 0.5)), url('../assets/IMG-20210701-WA0029.jpg') !important;
	background-repeat: no-repeat;
	background-size: cover;
}

.Resultats_Intro .Intro_text{
	height: auto;
	padding: 150px 100px 100px 100px;
}

.Resultats_Intro h1{
	color: white;
	font-size: 3.5rem;
	font-weight: bold;
	text-align: center;
	margin: 0;
}

.Resultats_Intro p{
	color: white;
	width: 600px;
	height: auto;
	margin-top: 0;
}

/* 
.Resultats_Intro img{
	width: 500px;
	height: auto;
} */

.Resultats_Boxes{
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 80px 120px;
	max-width: 1400px;
	margin: 0 auto;
}

.Resultats_Boxes .Resultats_Box{
	display: flex;
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	transition: transform 0.3s ease;
}

.Resultats_Boxes .Resultats_Box:hover {
	transform: translateY(-5px);
}

.Resultats_Boxes .Resultats_Box .Resulats_Title{
	width: 35%;
	background-color: #03213c;
	color: white;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
}

.Resultats_Boxes .Resultats_Box .Resulats_Title::after {
	content: '';
	position: absolute;
	right: -15px;
	top: 50%;
	transform: translateY(-50%);
	border-style: solid;
	border-width: 15px 0 15px 15px;
	border-color: transparent transparent transparent #03213c;
}

.Resultats_Boxes .Resultats_Box .Resulats_Title h2{
	color: white;
	font-weight: bold;
	font-size: 1.6rem;
	margin: 0;
	line-height: 1.3;
	word-wrap: break-word;
	hyphens: auto;
}

.Resultats_Boxes .Resultats_Box .Resulats_Description{
	width: 65%;
	padding: 30px 40px;
	background-color: #f9f9f9;
}

.Resultats_Boxes .Resultats_Box .Resulats_Description p{
	color: #333;
	line-height: 1.6;
	margin: 0 0 15px 0;
	font-size: 1rem;
}

.Resultats_Boxes .Resultats_Box .Resulats_Description p:last-child {
	margin-bottom: 0;
}

.Resultats_Quote{
	display: flex;
	background-color: #3cb371;
}

.Resultats_Quote img{
	width: 50%;
	height: auto;
	object-fit: cover;
}

.Resultats_Quote h1{
	background-color: #3cb371;
	color: white;
	font-weight: bold;
	display: grid;
	font-size: 3rem;
	padding: 0 100px 0 100px;
	justify-content: center;
	align-items: center;
}


@media screen and (max-width: 992px) {
	
	.Resultats_Intro h1{
		color: white;
		font-size: 2.5rem;
	}

	
	.Resultats_Intro img{
		display: none;
	}

	.Resultats_Intro .Intro_text{
		height: auto;
		padding: 50px 50px 50px 50px;
	}

	.Resultats_Intro p{
		color: white;
		width: 100%;
		height: auto;
		font-size: 0.8rem;
	}

	.Resultats_Boxes{
		padding: 40px 20px;
		gap: 30px;
	}

	.Resultats_Boxes .Resultats_Box{
		flex-direction: column;
		width: 100%;
	}

	.Resultats_Boxes .Resultats_Box .Resulats_Title{
		width: 100%;
		padding: 20px;
	}

	.Resultats_Boxes .Resultats_Box .Resulats_Title::after {
		display: none;
	}

	.Resultats_Boxes .Resultats_Box .Resulats_Title h2{
		font-size: 1.4rem;
		text-align: center;
		width: 100%;
	}

	.Resultats_Boxes .Resultats_Box .Resulats_Description{
		width: 100%;
		padding: 20px;
	}

	.Resultats_Boxes .Resultats_Box .Resulats_Description p{
		font-size: 0.9rem;
	}

	.Resultats_Quote{
		display: grid;
	}

	.Resultats_Quote img{
		width: 100%;
		height: auto;
	}

	.Resultats_Quote h1{
		background-color: #3cb371;
		color: white;
		display: block;
		font-size: 3rem;
		padding: 100px 70px 100px 70px;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
	.Resultats_Boxes {
		padding: 60px 80px;
	}

	.Resultats_Boxes .Resultats_Box .Resulats_Title {
		width: 38%;
	}
	
	.Resultats_Boxes .Resultats_Box .Resulats_Description {
		width: 62%;
	}

	.Resultats_Boxes .Resultats_Box .Resulats_Title h2 {
		font-size: 1.4rem;
		line-height: 1.2;
	}
}

@media screen and (max-width: 1298px) and (min-width: 992px) {
	
	.Resultats_Intro h1{
		color: white;
		font-size: 5rem; 
	}
}