*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	
}

.Actualite_Intro{
	margin-top: 0 !important;
}

.Actualite_Intro h1{
	font-family: 'Raleway', 'PT Sans', sans-serif;
	background-color: #286f4e;
	color: white;
	text-align: center;
	font-weight: bold;
	font-size: 3.5rem;
	padding: 200px 150px 150px 150px;
}



.Actualite_comp_exp{
	margin: 80px 80px 80px 80px;
	/* width: 100%; */
}

.Actualite_comp_exp .Actualite_header h2{
	color: #00632C;
	font-size: 2rem;
	font-weight: bold;
}

.Actualite_comp_exp .Actualite_boxes{
	margin-top: 40px;
	/* display: flex;
	justify-content: space-around; */
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	gap: 15px;
	padding: 0;
}

.Actualite_comp_exp .Actualite_boxes .Actualite_box{
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    margin: 30px 0 0 0;
    position: relative;
    background-image: linear-gradient(rgba(186, 171, 0, 0.5), rgba(186, 171, 0, 0.5)), url('../assets/1671217015713.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
}


.Actualite_comp_exp .Actualite_boxes .Actualite_box_Img img{
	width: 100%;
	object-fit: fill;
}

.Actualite_box .Actualite_box_text{
	padding: 25px 25px 25px 25px;
}

.Actualite_box .Actualite_box_text .Actualite_box_text_head p{
	background-color: #dddddd;
	width: 90px;
	padding: 5px;
}

.Actualite_box .Actualite_box_text .Actualite_box_text_tit p{
	margin-top: 8px;
	background-color: #00632C;
	font-weight: bold;
	color: white;
	padding: 10px;
}

.Actualite_box .Actualite_box_text .Actualite_box_text_cont p{
	margin: 20px 0 20px 0;
	text-align: justify;

	display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Actualite_box .Actualite_box_text_but{
	position: absolute;
	bottom: 0;
        left: 0;
	right: 0;
}

.Act_box_text_but .SavoirPlus{
	background-color: #dddddd;
	width: 100%;
	padding: 5px;
	border: none;
}

.Act_box_text_but .SavoirPlus a{
	text-decoration: none;
	color: black;
}

.Act_box_text_but .SavoirPlus:hover{
	background-color: #b4b4b4;
	cursor: pointer;
}

.Act_box_text_but .SavoirPlus .fas{
	margin-left: 10px;
}

.Actualite_Button{
	display: grid;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
}

.Actualite_Button .Act_Button{
	background-color: #3cb371;
	padding: 20px;
	color: white;
	font-weight: bold;
	text-decoration: none;
	font-size: 1.2rem;
}

.Actualite_Button .Act_Button:hover{
	background-color: #113f26;
}







.drawer_title{
	background-color: #113f26;
	margin-top: 30px;
	padding: 20px;
}
.drawer_title h2{
	color: white;
	font-weight: bold;
}

.drawer_contents_img{
	max-width: 100%;
	height: auto;
	display: flex;
	justify-content: space-around;
	margin-top: 50px;
}

.drawer_contents_img img{
	width: 300px;
	height: auto;
	border-radius: 10px;
}

.drawer_contents_det{
	margin: 50px 0 50px 0;
	display: grid;
	justify-content: center;
	align-items: center;
}

.drawer_contents_det_txt{
	width: 700px;
	height: auto;
	color: #00213d;
	text-align: justify;
}




.Act_details_Intro{
	margin-top: 0 !important;
	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.Act_details_Intro_Img{
    width: 100%;
}

.Act_details_Intro_Text {
    width: 100%;
	justify-content: center;
	align-items: center;
}

.Act_details_contents{
	padding: 0;
}


.Act_details_contents .loading-message{
	margin: 200px 0;
	text-align: center;
}

.Act_details_contents .Act_details_comp .Act_details_comp_exp{
	padding: 20px 300px;
}

.Act_details_contents .Act_details_comp .Act_details_comp_exp p img{
	width: 300px;
	height: auto;
	padding: 5px;
}

.Act_details_Intro h1{
	font-family: 'Raleway', 'PT Sans', sans-serif;
	color: #ffffff;
	text-align: center;
	font-weight: bold;
	font-size: 3.2rem;
	padding: 250px 150px 150px 150px;
	text-transform: uppercase;
}

/* 

@media screen and (min-width: 1400px) and (max-width: 2265px){

	.Act_details_contents{
		padding: 0 500px;
	}


	.Act_details_Intro h1{
		font-family: 'Raleway', 'PT Sans', sans-serif;
		background-color: #286f4e;
		color: #286f4e;
		text-align: center;
		font-weight: bold;
		font-size: 2rem;
		padding: 150px 50px 150px 50px;
	}


}

 */



.act_follow{
	background-color: #d0ffe9;
	font-weight: bold;
	font-size: 3.5rem;
	padding: 50px 50px 50px 50px;
	margin: 50px 300px 100px 300px;
}

.act_follow .act_follow_Intro h1{
	color: #286f4e;
	font-weight: bold;
	margin: 10px 0 !important;
	font-size: 2rem;
}

.act_follow .act_follow_Intro h2{
	color: #286f4e;
	margin: 0 !important;
	font-size: 1.2rem;
}

.act_follow .act_follow_Intro .plan_links .plan_link_icon i{
	color: #ffffff;
	font-weight: bold;
	background-color: #286f4e;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	font-size: 20px;
	display: grid;
	justify-content: center;
	align-items: center;
}


.plan_links{
	margin-top: 10px;
}

.plan_links .plan_link_text h5{
	color: #008F40;
}
.plan_link_icons{
	display: flex;
	gap: 20px;
}
.plan_link_icons .plan_link_icon{
	color: #3CB371;
	font-size: 3rem;
}

.plan_link_icons .plan_link_icon i:hover{
	color: #008F40;
}





@media screen and (min-width: 992px) and (max-width: 1400px){
	.Act_details_Intro h1{
		font-family: 'Raleway', 'PT Sans', sans-serif;
		color: #ffffff;
		text-align: center;
		font-weight: bold;
		font-size: 2rem;
		padding: 250px 50px 150px 50px;
	}

	.act_follow{
		background-color: #d0ffe9;
		font-weight: bold;
		font-size: 3.5rem;
		padding: 50px 50px 50px 50px;
		margin: 50px 50px 50px 50px;
	}

	.Act_details_contents .Act_details_comp .Act_details_comp_exp {
		padding: 20px 200px;
	}

}



@media screen  and (max-width: 992px) {
	

	.Actualite_Intro h1{
		font-size: 2.5rem;
		padding: 150px 10px 150px 10px;
	}

	.Actualite_comp_exp{
		padding: 0;
		margin: 20px;
		/* width: 100%; */
	}
	
	.Actualite_comp_exp .Actualite_header{
		margin-left: 0px;
	}
	
	.Actualite_comp_exp .Actualite_header h2{
		color: #00632C;
		font-size: 2.5rem;
	}
	
	.Actualite_comp_exp .Actualite_header p{
		font-size: 0.6rem;
	}

	.Actualite_comp_exp .Actualite_boxes{
		display: block;
	}

	
	.Actualite_comp_exp .Actualite_boxes .Actualite_box{
		margin-left: 0px;
		margin-top: 30px;
	}

	.drawer_contents_img{
		max-width: 100%;
		height: auto;
		display: block;
		margin-top: 50px;
	}
	
	.drawer_contents_img img{
		width: 100%;
		height: auto;
		border-radius: 10px;
		margin-top: 10px;
	}

	.drawer_contents_det_txt{
		width: 100%;
		height: auto;
		color: #00213d;
		text-align: justify;
	}

	.Act_details_Intro h1{
		font-family: 'Raleway', 'PT Sans', sans-serif;
		color: #ffffff;
		text-align: center;
		font-weight: bold;
		font-size: 2rem;
		padding: 250px 20px 150px 20px;
	}

	.act_follow{
		background-color: #d0ffe9;
		font-weight: bold;
		font-size: 3.5rem;
		padding: 20px 20px 20px 20px;
		margin: 20px 20px 20px 20px;
	}


	.Act_details_contents .Act_details_comp .Act_details_comp_exp {
		padding: 20px 50px;
	}

}